import React from "react"
import styled from "styled-components"

import { device } from "../ions/breakpoints"

const Wrapper = styled.div`
  margin-top: 50px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }

  h1 span,
  h3 span {
    background-color: var(--default);
    padding: 5px 10px;
    color: var(--white);
  }

  @media ${device.s} {
    margin-top: 0;
    align-items: center;
  }
`

const Back = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;

  a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 2rem;

    &:last-child {
      margin-left: 15px;
    }
  }
`

const PageNotFound = () => {
  return (
    <Wrapper>
      <div>
        <h1>
          Houston, the thing is <span>black</span>!
        </h1>
        <h3>
          Houston, a coisa está <span>preta</span>!
        </h3>
        <Back>
          <a href="/">
            <span role="img" aria-label="Versão portuguesa">
              🇵🇹
            </span>
          </a>{" "}
          <a href="/en">
            <span role="img" aria-label="English version">
              🇬🇧
            </span>
          </a>
        </Back>
      </div>
    </Wrapper>
  )
}

export default PageNotFound
